<template>
  <div :id="tooltip_id" class="mdc-tooltip" :class="tooltip_id" role="tooltip" aria-hidden="true">
    <div class="mdc-tooltip__surface">
      <i v-show="icon_active == true" class="material-icons">{{icon_name}}</i>
      {{tooltip_name}}
    </div>
  </div>
</template>



<script>
  import {MDCTooltip} from '@material/tooltip';

  export default {
    name: 'Tooltip',
    props: {
      tooltip_id: {type: String},
      tooltip_name: {type: String},
      icon_name: {type: String},
      icon_active: {type: Boolean}
    },
    data() {
      return {
          tooltip: {},
      };
    },
    mounted() {
      new MDCTooltip(document.querySelector('.mdc-tooltip' + '.' + this.tooltip_id));
    } 
  }
</script>



<style lang="scss" scoped>
  @use "@material/tooltip/styles";

  i {
    font-size: 12px;
  }
</style>