<template>
 <div class="pricing-container" v-if="_g_IsUserLogged">
  <div class="pricing" id="pricing-component">
    <div class="text-video-container">
      <h3 id="pricing-title" class="pricing-title">Planes de acceso a la web</h3>
      <p id="pricing-description" class="pricing-text">Desde Eurekers hemos creado distintos planes de precios para así adecuarlos a las necesidades de cada usuario.</p>
      <p id="pricing-welcome" class="pricing-text">Bienvenido a las herramientas de Eurekers</p>
      <div class="pricing-video">
        <iframe width="394" height="233" src="https://www.youtube.com/embed/D_3X_1rsHUo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="card-container">
      <!-- <CardPricing json_club, :active_plan="data_activePlan.club", data_button/> -->
      <CardPricing :card="plan_club" :active_plan="data_activePlan.club" :active_button="active_button" />
      <CardPricing :card="plan_silver" :active_plan="data_activePlan.silver" :active_button="active_button" />
      <CardPricing :card="plan_gold" :active_plan="data_activePlan.gold" :active_button="active_button" />
      <CardPricing :card="plan_platinum" :active_plan="data_activePlan.platinum" :active_button="active_button_platinum" />
    </div>
    <p id="pricing-change-plan-text" class="pricing-text margin-bottom-pricing">Precio por cambio de plan: 50€ (primer cambio gratuito). Precio por re-alta en la web, tras haberse dado de baja: 60€.</p>
  </div>
 </div>

<div v-else id="main-container" class="mdc-layout-grid__inner" >
  <div class="mdc-layout-grid__cell--span-12">
    <div class="iframe-container">
        <iframe name="pricing" id="pricing-id" style="overflow:hidden" width="100%" height="100%" frameBorder="0"></iframe>
    </div>
  </div>
</div>
</template>



<script>
import CardPricing from '@/components/helpers/CardPricing.vue';
import PlanClub from "@/views/configs/pricing/plan-club.json";
import PlanSilver from "@/views/configs/pricing/plan-silver.json";
import PlanGold from "@/views/configs/pricing/plan-gold.json";
import PlanPlatinum from "@/views/configs/pricing/plan-platinum.json";
import {mapGetters} from "vuex";

export default {
  name: 'Pricing',
  components: {
    CardPricing,
  },
  data() {
    return {
      plan_club: PlanClub,
      plan_silver: PlanSilver,
      plan_gold: PlanGold,
      plan_platinum: PlanPlatinum,
      data_activePlan: {
        club: false,
        silver: false,
        gold: true,
        platinum: false
      },
      active_button_platinum: true,
      active_button: false
    };
  },
  mounted() {
    this._chargeIframe();
    // user.plan == club
    // data_activePlan[user.plan] == true
    // data_user_can_switch_to_platinum = json te deberia de dar true o false,
  },
  computed: {
    ...mapGetters(["_g_IsUserLogged"])
  },
  methods: {
    _changeToDarkMode: function(){
      document.body.style.backgroundColor= "#0F0F1A";
      document.getElementById("pricing-component").style.backgroundColor = "#0F0F1A";
      document.getElementById("pricing-title").style.color = "white";
      document.getElementById("pricing-description").style.color = "white";
      document.getElementById("pricing-welcome").style.color = "white";
      document.getElementById("pricing-change-plan-text").style.color = "white";
    },

    _chargeIframe: function(){
      var pricing = document.getElementById("pricing-id");
      pricing.src = process.env.VUE_APP_API + '/curso-de-bolsa'
    }
  }
}
</script>



<style lang="scss" >  
  .pricing {
    width: 100%;
  }
  .text-video-container {
    color: #000010;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    p {
      margin: 0;
    }
  }
  .pricing-title {
    margin: 103px 0 16px;
    font-weight: bold;
    font-size: 34px;
    line-height: 36px;
  }
  .pricing-text {
    text-align: center;
    color: #000010;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .pricing-video {
    margin: 34px 0;
  }
  .card-container {
    margin: 0 ;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    justify-content: space-around;
    -webkit-align-items: stretch; 
    align-items: stretch; 
  }
  .margin-bottom-pricing {
    padding: 0 0 126px 0;
  }

  @media (max-width: 599px){
    .card-container {
      margin: 0;
    }
  }
  @media (min-width: 600px) and (max-width: 1023px) {
    .card-container {
      margin: 0;
    }
  }
  @media (min-width: 1024px){
    .card-container {
      margin: 0 40px 34px;
    }    
  }
</style>
