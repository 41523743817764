<template>
  <button class="mdc-button mdc-button--outlined"  :disabled="disabled_state" :class="button_key">
    <span class="mdc-button__label button-text">{{button_name}}</span>
  </button>
</template>



<script>
  import {MDCRipple} from '@material/ripple';

  export default {
    name: 'ButtonOutline',
    props: {
      button_name: {type: String},
      button_key: {tyep: String},
      disabled_state: {type: Boolean}
    },
    data() {
      return {
      };
    },
    mounted() {
      const button = new MDCRipple(document.querySelector('.mdc-button' + '.' + this.button_key));
      button.getDefaultFoundation().adapter.registerInteractionHandler('click', () => {
        this.$emit('_emit_method_button_outlined', [this.button_key] );
      })
  
    },
    methods: {
    }
  }
</script>



<style lang="scss" scoped>
  @use "@material/button";
  .mdc-button {
    @include button.shape-radius(50% , 0, 0);
    @include button.container-fill-color(('default':rgba(255, 255, 255, 0.3), 'disabled':rgba(255, 255, 255, 0.1) ));
    @include button.ink-color(('default':#FFFFFF, 'disabled':rgba(255, 255, 255, 0.4) ));
    @include button.outline-color(#FFFFFF);
    @include button.horizontal-padding(40px);
  }
  .button-text {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 14px;
  }
</style>
