<template>
  <div class="mdc-card mdc-card--outlined" :class="{ 'active-plan-color' : active_plan == true }">
   <div class="aling-top">
    <h5 class="card-pricing-title">{{card.title}}</h5>
    <hr>
    <div v-show="!card.extra == ''">
      <p class="card-pricing-text-exclusive">{{card.extra}}</p>
      <hr>
    </div>
    <ul>
      <h6 class="card-pricing-subtitle" :class="card.button_key">{{card.subtitle}}</h6>
      <li v-for="(item, index) in card.list" :key="index">
        <span class="material-icons card-pricing-icon">done</span> 
        <p class="card-pricing-text">{{item}}</p>
      </li>
    </ul> 
   </div>

    <div class="card-pricing-align-end">
      <p class="card-pricing-price">{{card.price}}€ <span style = "font-weight: normal; font-size: 14px;"> / mes</span></p>
      <p class="card-pricing-text-small">{{card.text_info}}</p>
      <div :aria-describedby="card.tooltip_id">
        <ButtonOutline @_emit_method_button_outlined="_click" :button_name="button_name" :button_key="card.button_key" :disabled_state="active_button" />
        <Tooltip v-show="card.tooltip_id == 'button_disabled'" :tooltip_name="tooltip_name" :tooltip_id="card.tooltip_id" :icon_name="card.icon_name" :icon_active="card.icon_active" />
      </div>
    </div>
  </div>
</template>



<script>
  import ButtonOutline from '@/components/helpers/ButtonOutline.vue';
  import Tooltip from '@/components/helpers/Tooltip.vue'
  export default {
    name: 'CardPricing',
    components: {
    ButtonOutline,
    Tooltip
  },
    props: {
      active_plan: {type: Boolean},
      card: {type: Object},
      active_button: {type: Boolean}
    },
    data() {
      return {
        button_name: "Suscribirse",
        tooltip_name: "No cumples con los requisitos para pasar al plan platinum",
        icon_name: "block",
        icon_active: true,
        
      };
    },
    mounted() {
      
    },
    methods: {
    }
  }
</script>



<style lang="scss" scoped>
  @use "@material/card";
  @include card.core-styles;
  
  .card-pricing-subtitle.button-club {
    font-size: 0;
  }
  .active-plan-color {
    background: linear-gradient(180deg, #00303D 0%, #016882 51.04%, #00303D 100%);
  }

  .card-pricing-align-end {
    
    .mdc-button {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: 24px 20% 31px;
    }
  }	
  .mdc-card {
    height: auto;
    justify-content: space-between;
    margin-bottom: 20px; 
    @include card.fill-color(#161824);
    @include card.outline(#161824);
    @include card.shape-radius(20px)
  }
  .card-pricing-title {
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 25px;
    margin: 34px 0 21px;
  }
  .card-pricing-subtitle {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin: 23px 0 17px;
  }
  hr {
    width: 67%;
    margin: 0 auto;
  }
  .card-pricing-icon {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
  }

  .card-pricing-text {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 0 7px;
  }

  .card-pricing-text-exclusive {
    color: #FFFFFF;
    margin: 7px 80px;
    font-size: 10px;
    line-height: 14px;
  }

  ul {
    margin-right: 10px;
  }

  li {
    display: flex;
    margin-top: 7px;
    margin-right: 30px;
  }

  .card-pricing-price {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 95px; 
    margin-bottom: 0;
  }

  .card-pricing-text-small {
    color: #FFFFFF;
    margin: 0 30px;
    font-size: 10px;
    line-height: 14px;
  }

  @media (max-width: 599px){
    .mdc-card {
      width: 100%;
      // height: 700px;
  }
  }
  @media (min-width: 600px) and (max-width: 1439px) {
    .mdc-card {
      width: 48%;
      // height: 800px;
    }
  }
  @media (min-width: 1440px){
    .mdc-card {
      width: 23%;
      // height: 800px;
    }    
  }
</style>
